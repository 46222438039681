/* eslint-disable */
export function isLeadbacker() {
    const role = localStorage.getItem('role')
    if(role === '11'){
        return true
    }else{
        return false
    }
}

export function isFranchiseOwner(){
    let cbroles = ['11', '10', '9']
    const role = localStorage.getItem('role')
    if(role.startsWith("F0")){
        return true
    }else{
        return false
    }

}
