<template>
  <body class="bg-[url]">
  <section>
  <div class="flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
   
      <div class="w-full bg-white rounded-2xl shadow dark:border md:mt-0 sm:max-w-md xl:p-0 dark:bg-gray-800 dark:border-gray-700">
          <div class="p-6 space-y-4 md:space-y-6 sm:p-8 ">
            <a href="#" class="flex items-center justify-center mb-6 text-2xl font-semibold text-gray-900 dark:text-white text-center">
                <img class="h-7 w-100 mr-2" src="media/cb-logo.png" alt="logo">
                </a>
                <h1 class="text-4xl font-bold flex justify-center leading-tight tracking-tight text-blue dark:text-white">
  Log In to Growth CRM
</h1>
              <div class="space-y-4 md:space-y-6">
                  <div>
                      <label for="email" class="block mb-1 text-sm font-medium text-gray-900 dark:text-white">Cyberbacker Email</label>
                      <input type="email" name="email" v-model="email" id="email" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                  </div>
                  <div>
                      <label for="password" class="block mb-2 text-sm font-medium text-gray-900 dark:text-white">Password</label>
                      <input :type="inputtype" name="password" v-model="password" id="password" class="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-primary-600 focus:border-primary-600 block w-full p-2.5 dark:bg-gray-700 dark:border-gray-600 dark:placeholder-gray-400 dark:text-white dark:focus:ring-blue-500 dark:focus:border-blue-500" required="">
                  </div>
                  <div class="flex items-center justify-between">
                      <div class="flex items-start">
                          <div class="flex items-center h-5">
                            <input id="remember" aria-describedby="remember" v-model="show_password" type="checkbox" class="w-4 h-4 border border-gray-300 rounded bg-gray-50 focus:ring-3 focus:ring-primary-300 dark:bg-gray-700 dark:border-gray-600 dark:focus:ring-primary-600 dark:ring-offset-gray-800" required="">
                          </div>
                          <div class="ml-3 text-sm">
                            <label for="remember" class="text-gray-500 dark:text-gray-300">Show Password</label>
                          </div>
                      </div>
                  </div>
                 
                  <button type="submit" v-on:click="loginAccount" class="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded">Log in</button>
                </div>
          </div>
         
      </div>
      <div class="mt-10 text-white">
  <a><span class="text-blue !important">CREATING JOBS</span></a><a class="italic">to provide a high-quality standard of living to high-caliber Cyberbackers.</a>
</div>
  </div>
</section>
</body>
  </template>
  <script>
  import { loginData, loginMethods, loginWatchers } from './login'
  export default {
      name: 'LoginPage',
      data: function () {
        return loginData
      },
      watch: loginWatchers,
      methods:  loginMethods,
    }
  </script>