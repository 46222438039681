<template>
    <div v-if="loading" class="loading-indicator">
      <img :src="$baseUrl + 'media/loading.gif'">
        </div>
  </template>
  
  <script>
  export default {
    props: {
      loading: {
        type: Boolean,
        default: false
      }
    }
  }
  </script>
  
  <style>
  .loading-indicator {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .loading-bar {
    width: 100px;
    height: 10px;
    background-color: rgb(0, 0, 0);
    animation: loading-bar 1s infinite;
  }
  
  @keyframes loading-bar {
    0% {
      width: 0;
    }
    50% {
      width: 50%;
    }
    100% {
      width: 100%;
    }
  }
  </style>  