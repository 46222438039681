import LoginPage from '@/components/views/login/LoginPage.vue'

/* eslint-disable no-unused-vars */
const loginRoutes = [
  {
    path: '/',
    name: 'login',
    component: LoginPage
  }
]
/* eslint-disable no-unused-vars */

export default loginRoutes