import { createRouter, createWebHistory } from 'vue-router'
import appRoutes from './appRoutes'
import loginRoutes from './loginRoutes'
import { isAuthenticated } from '@/auth'

/* eslint-disable no-unused-vars */
const router = createRouter({
  history: createWebHistory(),
  routes: [
    ...appRoutes,
    ...loginRoutes
  ]
})

router.beforeEach((to, from, next) => {
  const isLoggedIn = isAuthenticated();
  if (to.path !== '/' && !isLoggedIn) {
    next('/')
    // If the route requires authentication and the user is not authenticated, redirect to the login page
  } else {
    // Otherwise, allow the user to proceed to the route
    next()
  }
})
/* eslint-disable no-unused-vars */
export default router