import axios from "axios";

// Create a new Axios instance
// Add an interceptor to show or hide the progress bar
// Add a request interceptor

export default {
  install: (app) => {
    (app.config.globalProperties.$axios = axios),
      (axios.defaults.baseURL = process.env.VUE_APP_API_URL);
    axios.interceptors.request.use(
      (config) => {
        // Do something before sending the request
        app.config.globalProperties.$loading = true; // set loading to true
        return config;
      },
      (error) => {
        // Do something with request error
        app.config.globalProperties.$loading = false; // set loading to false
        return Promise.reject(error);
      }
    );

    // Add a response interceptor
    axios.interceptors.response.use(
      (response) => {
        // Do something with response data
        app.config.globalProperties.$loading = false; // set loading to false
        return response;
      },
      (error) => {
        // Do something with response error
        app.config.globalProperties.$loading = false; // set loading to false
        return Promise.reject(error);
      }
    );
  },
};
