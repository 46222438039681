/* eslint-disable */
export function isAuthenticated() {
  // Check if a JWT is stored in localStorage
  const token = localStorage.getItem("token");

  // If there is no token, the user is not authenticated
  if (!token) {
    return false;
  }

  // Verify the token on the server-side to ensure it hasn't been tampered with
  // Return true if the token is valid, false otherwise
  return true;
}
