import { createApp } from "vue";
import App from "./App.vue";
import router from "./router/router";
import axiosPlugin from "./axios.js";
import "flowbite-datepicker";
import "./style.css";
import VueApexCharts from "vue3-apexcharts";
import SweetAlert from "sweetalert2";
import { isLeadbacker, isFranchiseOwner } from "./plugins/role";
import "flowbite";

const app = createApp(App);

const baseUrl = window.location.protocol + "//" + window.location.host + "/";
app.config.globalProperties.$baseUrl = baseUrl;
app.config.globalProperties.$isLeadbacker = isLeadbacker;
app.config.globalProperties.$isFranchiseOwner = isFranchiseOwner;

app.use(VueApexCharts);
app.use(router);
app.use(axiosPlugin);

const sweetAlertInstance = SweetAlert.mixin({
  // Set default options for SweetAlert
});

app.config.globalProperties.$swal = sweetAlertInstance;

app.mount("#app");
