/* eslint-disable no-unused-vars */
import router from "@/router/router"
export const loginData = {
    email: "",
    password: "",
    show_password: "",
    inputtype: "password"
}

export const loginWatchers = {
    show_password: function(newVal, oldValue){
        if(newVal){
           this.inputtype = "text"
        }else{
           this.inputtype = "password"
        }
    }, 

}
export const loginMethods = {
    async loginAccount(){
        let params = {
            email: this.email,
            password: this.password
        }
        this.$axios.post('login', params).then(response => {
            let user = response.data.data
            console.log(user.id)
            if(user.id){
                localStorage.setItem("email", this.email);
                localStorage.setItem("userid", user.id);
                localStorage.setItem("role", user.role);
                localStorage.setItem("name", user.name);
                localStorage.setItem("token", user.api_token);
                localStorage.setItem("subroles", user.subroles);
                if(user.role != '11' && !user.role.startsWith("F0")){
                    router.push('/myleads')
                }else{
                    router.push('/growthcrm')
                }
            }else{
                this.$swal.fire({
                    toast: false,
                    icon: "warning",
                    title: 'Invalid credentials please contact the administrator',
                    confirmButtonText: 'Ok',
                  })
            }
       
        })
    },
    async logoutAccount(){
        localStorage.setItem("token", "");
    }
}