const AppAbout = () => import('@/components/views/about/AppAbout.vue')
const HomeDashboard = () => import('@/components/views/home/HomeDashboard.vue')
const FollowUp = () => import('@/components/views/followup/followup.vue')
const ClientsPage = () => import('@/components/views/clients/ClientsPage.vue')
const AddLead = () => import('@/components/views/leads/addleads/AddLead.vue')
const MyLeads = () => import('@/components/views/leads/myleads/MyLeads.vue')
const AppLayout = () => import('@/components/layout/AppLayout.vue')
const GrowthAttrition = () => import('@/components/views/attrition/GrowthAttrition.vue')
const LeadDetails = () => import('@/components/views/leads/leaddetails/LeadDetails.vue')
const ChangeLogs = () => import('@/components/views/notifications/ChangeLog.vue')
const ChangePassword = () => import('@/components/views/account/ChangePassword.vue')
const GltData = () => import('@/components/views/leads/glt/GltData.vue')
const LeadSources = () => import('@/components/views/admin/LeadSources.vue')
const FranchiseSettings = () => import('@/components/views/admin/FranchiseSettings.vue')

const childRoutes = [
  {
    path: '/growthcrm',
    name: 'growthcrm',
    component: HomeDashboard,
    meta: {
      auth: true,
    },
  },
  {
    path: '/about',
    component: AppAbout,
    meta: {
      auth: true,
    },
  },
  {
    path: '/followup',
    component: FollowUp,
    meta: {
      auth: true,
    },
  },
  {
    path: '/clients',
    component: ClientsPage,
    meta: {
      auth: true,
    },
  },
  {
    path: '/addleads',
    component: AddLead,
    meta: {
      auth: true,
    },
  },
  {
    path: '/myleads',
    component: MyLeads,
    meta: {
      auth: true,
    },
  },
    {
      path: '/attrition',
      component: GrowthAttrition,
      meta: {
        auth: true,
      },
  },
  {
    path: '/leaddetails/:leadid',
    component: LeadDetails,
    meta: {
      auth: true,
    }
  },
  {
    path: '/mynotifications',
    component: ChangeLogs,
    meta: {
      auth: true,
    }
  },
  {
    path: '/password',
    component: ChangePassword,
    meta: {
      auth: true,
    }
  },
  {
    path: '/franchisesettings',
    component: FranchiseSettings,
    meta: {
      auth: true,
    }
  },
  {
    path: '/glt',
    component: GltData,
    meta: {
      auth: true,
    }
  },
  {
    path: '/leadsources',
    component: LeadSources,
    meta: {
      auth: true,
    }
  },
]

const appRoutes = [
  {
    path: '/growthcrm',
    component: AppLayout,
    children: childRoutes,
    meta: {
      auth: true,
    },
  }
]

export default appRoutes





