<template>
<loading-indicator :loading="loading"></loading-indicator>
<router-view></router-view>
</template>
<script>
import LoadingIndicator from '@/components/statics/LoadingIndicator.vue'
import axios from 'axios'
export default {
  components: {
    LoadingIndicator
  },
  data() {
    return {
      loading: false
    }
  },
  created() {
    // Add request interceptors
    axios.interceptors.request.use((config) => {
      this.loading = true;
      return config;
    }, (error) => {
      this.loading = false;
      return Promise.reject(error);
    });

    // Add response interceptors
    axios.interceptors.response.use((response) => {
      this.loading = false;
      return response;
    }, (error) => {
      this.loading = false;
      return Promise.reject(error);
    });
  },
}
</script>